* {
  box-sizing: border-box;
}

html {
  background-image: url('images/galaxy.jpg');
  background-size: cover;
  font-family: sans-serif;
}

.game-container {
  position: relative;
  width: 90%;
  padding-bottom: 90%;
  border: 5px ridge #0077ffd2;
  background-color: #000000ea;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}

@media only screen and (min-width: 800px) {
  .game-container {
    width: 600px;
    padding-bottom: 600px;
    margin: auto;
  }
}

.game-area {
  position: absolue;
  margin: auto;
  width: 100%;
  height: 100%;
}


.snake-dot {
  position: absolute;
  width: 5%;
  height: 5%;
  background: radial-gradient(#FFFFFF, rgb(255, 0, 234));
  border: 1px solid rgb(255, 0, 234);
  border-radius: 10%;
  /* background-image: url('gem.png');
  background-size: cover; */
  /* box-shadow: 2px 5px 10px rgba(0,0,0,0.3); */
}

.snake-food {
  position: absolute;
  width: 5%;
  height: 5%;
  /* background-image: url('lightning.png');
  background-size: cover; */
  background: radial-gradient(#FFFFFF, rgb(255, 217, 0));
  border: 1px solid rgb(255, 217, 0);
  box-shadow: 1px 1px 50px 10px rgb(255, 255, 255);
  border-radius: 50%;
  z-index: 1;
}

.overlay-container {
  position: fixed;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.842);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 10%;
  top: 0;
  left: 0;
  color: white;
  text-align: center;
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

h1 {
  color: rgb(255, 240, 154);
  font-size: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

span {
  color: rgb(255, 0, 234);
  font-weight: bold;
}

p {
  font-size: 16px;
  color: white;
  line-height: 150%;
}


.instructions {
  font-size: 16px;
  line-height: 190%;
  width: 100%;
  max-width: 500px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

button, .button {
  cursor: pointer;
  background: linear-gradient(45deg, rgb(194, 0, 178), rgb(0, 82, 175));
  color: white;
  border-radius: 5px;
  font-size: 22px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  max-width: 100%;
  transition: all ease-in-out 0.1s;
}

.new-game-button {
  height: 50px;
  margin-top: 25px;
  width: 250px;
}

.sign-in-link {
  color: rgb(255, 0, 234);
  cursor: pointer;
  transition: all ease-in-out 0.1s;

}

.sign-in-link:hover, .sign-in-link:focus {
  color: white;
}

.button-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.arrow-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.up-down-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.arrow-buttons {
  width: 50px;
  height: 50px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  margin: auto;
  width: 100%;
}

/* Clearfix*/
.row::before, 
.row::after {
  display: table;
  content: '';
}

.row::after {
  clear: both;
}


/*Mobile defaults */
.col-6 {
  float: left;
  width: 100%;
  padding: 2%;
}

.col-6-b {
  float: left;
  width: 50%;
  padding: 2%;
}

.score-row {
  max-width: 600px;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 0;
  padding-bottom: 0;
}

.box {
  width: 100%;
  background-color: rgb(0, 0, 0);
  box-shadow: 5px 10px 55px 5px rgba(255, 254, 246, 0.589);
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

form {
  width: 100%;
  font-size: 16px;
}

input {
  margin-bottom: 10px;
}

input[type=text] {
  width: 100%;
  padding: 4px;
  border-radius: 5px;
}

.button {
  width: 100%;
  height: 50px;
  margin-top: 10px;
}

legend {
  color: rgb(255, 240, 154);
  text-align: left;
  font-size: 20px;
  margin-bottom: 15px;
}

label {
  text-align: left;
}


@media only screen and (min-width: 568px) {
  .col-6 {
    width: 50%;
  }
  h1 {
    font-size: 40px;
  }
  p {
    font-size: 20px;
  }
}

.button:hover, button:hover, .sign-in-link:hover {
  transform: translate(0, -1.5px) scale(1.01)
}
